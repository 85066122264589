import {
  Languages,
  Main,
  MenuStoreContextType,
  useQuery,
  useRootStore as useRootStoreWatermelon,
  useTranslation,
} from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OspFull from './assets/logos/osp_full';
import LogoPoolOSP from './assets/logos/osp_pool';
import { Query, QueryParkingsArgs } from './client/graphql';
import { parkingsGql } from './common/gql/parkings.gql';
import { RunWizard } from './components/run-wizard/run-wizard.component';
import { SearchBar } from './components/searchbar/searchbar.component';
import { config } from './config';

export const MainUse = observer(() => {
  const { AuthStore, GQLStore, I18NStore, MenuStore, WizardStore } =
    useRootStoreWatermelon();

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, lang } = useTranslation();

  const me = AuthStore.user as Query['me'] | null;

  const parkingsState = useQuery<
    { parkings: Query['parkings'] },
    QueryParkingsArgs
  >(parkingsGql, { variables: { limit: 300 } });

  const parkings = useMemo(() => {
    return parkingsState.data?.parkings?.list;
  }, [parkingsState.data]);

  const logos = useMemo(() => {
    switch (config.PORTAL) {
      case 'tenant':
        return LogoPoolOSP(t);
      case 'operator':
        if (me && me?.operator?.display) {
          const logos: React.ReactNode[] = [];
          const display = me.operator.display;

          if (display.logoSecondary) {
            logos.push(
              <img
                key={`secondary`}
                style={{ height: '1.56rem' }}
                src={display.logoSecondary}
                alt="Reseller logo"
              />,
            );
          }

          if (display.logoMain) {
            logos.push(
              <img
                key={`primary`}
                style={{
                  maxHeight: '6.25rem',
                  height: '100%',
                  maxWidth: '80%',
                }}
                src={display.logoMain}
                alt="Operator logo"
              />,
            );
          }

          return logos;
        }

        return [];
      default:
        return [];
    }
  }, [me, config.PORTAL, lang]);

  useEffect(() => {
    if (AuthStore.token && GQLStore.client && GQLStore.introspection) {
      AuthStore.setMe({
        client: GQLStore.client,
        introspection: GQLStore.introspection,
      });
    }
  }, [AuthStore.token, GQLStore.client, GQLStore.introspection]);

  useEffect(() => {
    if (me) {
      AuthStore.setAccount({
        firstName: me.firstName || '',
        lastName: me.lastName || '',
        email: me.username || '',
      });

      const { lang } = me;

      I18NStore.updateLang(lang as Languages);
    }
  }, [me]);

  useEffect(() => {
    // Handle redirection at first loading
    if (pathname === '/' && parkings && parkings.length > 0) {
      navigate(`/${parkings[0]._id}/dashboard`);
    }
  }, [parkings]);

  useEffect(() => {
    if (parkings) {
      MenuStore.setMenu([
        {
          key: 'parkings',
          position: 'top',
          title: t('parkings'),
          items: parkings.map((p) => {
            const items: MenuStoreContextType['menu'][0]['items'] = [];

            items.push({
              key: `/${p._id}/dashboard`,
              label: t('dashboard'),
              icon: 'faHomeSolid',
            });

            items.push({
              key: `/${p._id}/sessions`,
              label: t('sessions'),
              icon: 'faClockSolid',
            });

            items.push({
              key: `/${p._id}/contracts`,
              label: t('contracts'),
              icon: 'faIdCardSolid',
              extra: (
                <RunWizard
                  handleEvent={{
                    click: () => {
                      WizardStore.select({
                        id: 'choiceCreateContract',
                        store: {
                          parkingId: p._id,
                          mode: config.PORTAL,
                          actions: {
                            createNewSingleContract:
                              config.PORTAL === 'operator',
                            createNewMasterPoolContract:
                              config.PORTAL === 'operator',
                            createNewSubPoolContract: true,
                            addOneMotoristToAnExistingPoolContract: true,
                            addSeveralMotoristsToAnExistingPoolContract: true,
                          },
                        },
                      });
                    },
                  }}
                />
              ),
            });

            if (config.PORTAL === 'operator') {
              items.push({
                key: `/${p._id}/breakdowns`,
                label: t('repairSessions'),
                icon: 'faTriangleExclamationSolid',
                extra: (
                  <RunWizard
                    handleEvent={{
                      click: () =>
                        WizardStore.select({
                          id: 'reportTechnicalDeviceFailure',
                          store: {
                            parkingId: p._id,
                          },
                        }),
                    }}
                  />
                ),
              });
            }

            items.push({
              key: `/${p._id}/incidents`,
              label: t('incidents'),
              icon: 'faCircleExclamationSolid',
            });

            if (config.PORTAL === 'operator') {
              items.push({
                key: `/${p._id}/products`,
                label: t('products'),
                icon: 'faBoxSolid',
              });
            }

            return {
              key: `/${p._id}/dashboard`,
              label: p.name,
              icon: 'faSquareParkingSolid',
              items: items,
            };
          }),
        },
        {
          key: 'myCompany',
          position: 'top',
          title: t('myCompany'),
          items: [
            {
              key: '/motorists',
              label: t('motorists'),
              icon: 'faUserSolid',
              extra: (
                <RunWizard
                  handleEvent={{
                    click: () =>
                      WizardStore.select({
                        id: 'createSeveralMotorists',
                        store: {
                          parkingId: null,
                        },
                      }),
                  }}
                />
              ),
            },
          ],
        },
      ]);
    }
  }, [parkings, lang]);

  return (
    <Main
      handleEvent={{
        selected: (key: string) => {
          navigate(key);
        },
      }}
      config={{
        navigation: {
          logo: logos,
          mode: 'drop',
        },
        header: {
          logo: OspFull(),
          extra: <SearchBar></SearchBar>,
        },
      }}
    />
  );
});
